<template>
  <div class="product-recall">
    <v-container class="relative">
      <v-row>
        <v-col
          cols="0"
          md="3"
          order="last"
          order-md="first"
          class="pa-0 pa-md-3"
        >
          <SupportCard class="mb-0 mb-md-5" />
        </v-col>
        <v-col cols="12" md="9">
          <h2 v-if="page.title" class="custom-title mb-8 mt-6 mt-sm-0">
            {{ page.title.rendered }}
          </h2>
          <div
            v-if="page.content"
            class="page mb-12 mb-sm-0"
            v-html="page.content.rendered"
            @click="clicked"
          ></div>
          <v-list flat>
            <v-card
              flat
              v-for="product in products"
              :key="product.ID"
              class="my-7"
            >
              <v-row v-if="product.content">
                <v-col cols="12" md="6" class="d-flex align-center flex-row">
                  <img
                    v-if="product.featured_image_url"
                    width="100"
                    height="100"
                    :src="product.featured_image_url"
                    :alt="'Immagine prodotto' + product.title.rendered"
                  />
                  <div class="d-flex flex-column">
                    <span class="date mb-3">
                      {{ $dayjs(product.date).format("DD/MM/YYYY") }}
                    </span>
                    <span
                      class="product mb-4"
                      v-html="product.title.rendered"
                      @click="clicked"
                    />
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card-text
                    class="product-info"
                    v-html="product.content.rendered"
                    @click="clicked"
                  />
                  <v-card-text
                    v-if="product.file_pdf.guid"
                    class="download-link text-uppercase font-weight-bold py-0 mb-8"
                  >
                    <a
                      class="d-flex align-center"
                      color="primary"
                      :href="product.file_pdf.guid"
                      target="_blank"
                    >
                      {{ $t("productRecall.download") }}
                      <img src="/download.svg" class="ml-2" contain />
                    </a>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import SupportCard from "@/components/cards/SupportCard";
import CMSService from "~/service/cmService";

import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";

export default {
  name: "PageProductRecall",
  data() {
    return {
      products: null,
      fab: false
    };
  },
  mixins: [clickHandler],
  props: { page: { type: Object, required: true } },
  computed: {
    content() {
      return get(this.page, "content.rendered");
    }
  },
  components: { SupportCard },
  methods: {
    async reload() {
      let res = await CMSService.getCustomPostByFilters("richiamo_prodotti");
      this.products = res.data;
    }
  },
  mounted() {
    this.reload(this.$route.params.categoryName);
  }
};
</script>

<style scoped lang="scss">
.product-recall {
  .custom-title {
    color: var(--v-secondary-base);
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 20px;
    }
  }
  .page {
    h3 {
      font-weight: 400 !important;
      font-size: 22px;
      line-height: 33px;
      color: var(--v-grey) !important;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 17px;
      }
    }
  }
  .v-list {
    .v-card {
      border-bottom: 1px solid #c4c4c4;
      .date {
        font-size: 12px;
      }
      .product-info {
        font-size: 15px !important;
        line-height: 22px;
      }

      .download-link {
        font-size: 12px !important;
        a {
          color: $primary;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
