var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"static-page",class:_vm.page.slug},[_c('page-title',{staticClass:"hidden-xs-only",attrs:{"page":_vm.page}}),_c('Breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c(_vm.templateName,{key:_vm.key,tag:"component",attrs:{"page":_vm.page}}),(this.page && this.page.slider_img)?_c('ImgSlider',{attrs:{"imgs":this.page.slider_img}}):_vm._e(),_c('v-container',[(
        this.page &&
          (this.page.slug == 'diventa-cliente' ||
            this.page.slug == 'assortimento')
      )?_c('FooterCardsGrid',{attrs:{"cardsName":['serviziSpesa', 'storeLocator']}}):_vm._e()],1),(
      !_vm.isAuthenticated && this.page && this.page.slug != 'diventa-cliente'
    )?_c('BeCustomerCardHorizontal',{attrs:{"card":"registrationHome"}}):(this.page && this.page.banner_faq == '1')?_c('FaqBar',{staticClass:"mt-5"}):_vm._e(),_c('NewsletterHorizontal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }