<template>
  <v-card
    color="primary lighten-1"
    elevation="0"
    class="pa-2 d-flex flex-column align-center  white--text"
    height="220px"
    :style="small ? 'background-image: url(\'/cards/faq-card-bg.jpg\');' : ''"
  >
    <h3 class="title text-center mt-4">
      <strong>{{ $t("leftColumnCards.faq.title") }}</strong>
    </h3>
    <v-btn depressed color="primary" :to="'/faq'" class="contact-button white">
      {{ $t("leftColumnCards.faq.button") }}
    </v-btn>
  </v-card>
</template>
<style scoped lang="scss">
.v-card {
  background-size: cover;
  background-position: center;
  background-image: url("/cards/faq-banner.png");
  @media #{map-get($display-breakpoints, 'xs-only')} {
    background-image: url("/cards/faq-card-bg.jpg");
  }
}
.help-img {
  width: 60px;
  height: 60px;
}
.v-btn {
  bottom: 30px;
  position: absolute;
  .v-btn__content {
    color: $white;
  }
}
h3.title {
  line-height: 24px;
}
</style>
<script>
export default {
  name: "FaqCard",
  // forza l'immagine di background utilizzando quella piccola
  props: ["small"]
};
</script>
