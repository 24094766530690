<template>
  <v-card
    class="proposal-card d-flex flex-column"
    rounded="sm"
    flat
    :style="{
      color: color,
      backgroundColor: backgroundColor
    }"
    :href="link"
    @click.prevent="handleLink"
  >
    <v-img
      :src="src"
      height="201"
      max-height="201"
      :title="'logo' + proposal.descr"
      :alt="'Immagine' + proposal.descr"
    />
    <v-card-text
      class="pre-slide-text align-center text-uppercase"
      v-html="proposal.descr"
      :style="{
        color: color
      }"
      @click="clicked"
    ></v-card-text>
    <v-card-text
      class="content pa-3"
      v-if="showContent && proposal.content"
      v-html="proposal.content"
      @click="clicked"
    ></v-card-text>
    <v-spacer />
  </v-card>
</template>
<style scoped lang="scss">
.pre-slide-text {
  width: initial;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
}
.v-image {
  height: 201px;
}
.content {
  font-size: 12px;
  line-height: 18px;
}
</style>
<script>
import banner from "~/mixins/banner";
import clickHandler from "~/mixins/clickHandler";

import get from "lodash/get";

export default {
  name: "ProposalCard",
  props: ["proposal", "showContent"],
  mixins: [banner, clickHandler],
  computed: {
    color() {
      return get(this.proposal, "metaData.category_proposal_type.COLOR");
    },
    backgroundColor() {
      return get(this.proposal, "metaData.category_proposal_type.BG_COLOR");
    },
    src() {
      let prop =
        "metaData.category_proposal_type." +
        (this.$vuetify.breakpoint.xs ? "IMAGE_MOBILE" : "IMAGE_DESKTOP");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
