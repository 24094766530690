<template>
  <v-container fluid class="wp-img-slider mb-10 px-0 py-10">
    <div class="d-flex justify-center" @click="clicked">
      <h2 v-html="title" v-if="title"></h2>
    </div>
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide v-for="img in imgs" :key="img.id">
        <!-- :to="{ name: 'Flyer', params: { volantino: proposal.slug } }" -->
        <v-card rounded="md" elevation="2" class="proposal-img">
          <v-img
            :src="img.guid"
            alt="Immagine slider"
            class="wp-slider-image align-center"
          >
          </v-img>
        </v-card>
      </swiper-slide>
    </swiper>
    <div
      class="swiper-pagination-wp"
      :class="paginationClass"
      slot="pagination"
    ></div>
  </v-container>
</template>
<style lang="scss">
.wp-img-slider {
  background-color: var(--v-primary-base);
  .v-image.wp-slider-image {
    .v-response__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .swiper-pagination-wp {
    position: initial;
    text-align: center;
    .swiper-pagination-bullet {
      background-color: $white;
      opacity: 0.5;
    }
    .swiper-pagination-bullet-active {
      background-color: $white;
      opacity: 1;
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "ImgSlider",
  props: {
    imgs: { type: Array, required: true },
    paginationClass: { type: String, default: "swiper-pagination-offer" }
  },
  mixins: [banner, clickHandler],
  data() {
    return {
      swiperOption: {
        slidesPerView: this.slidesPerView,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        pagination: {
          el: "." + this.paginationClass,
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 1.5,
            centeredSlides: true
          },
          600: {
            slidesPerView: 1.5,
            centeredSlides: true
          },
          960: {
            slidesPerView: 2,
            centeredSlides: true
          },
          1024: {
            slidesPerView: 2.5,
            centeredSlides: true
          },
          1280: {
            slidesPerView: 2.5,
            centeredSlides: true
          }
        }
      }
    };
  }
};
</script>
