<template>
  <div class="header-banner" v-intersect.once="handleView">
    <div class="d-flex justify-center">
      <h2 v-html="title" v-if="title" @click="clicked"></h2>
    </div>
    <v-row>
      <v-col
        v-for="proposal in proposals"
        :key="proposal.id"
        class="d-flex"
        cols="6"
        sm="6"
        lg="3"
      >
        <ProposalCard :proposal="proposal" :showContent="showProposalContent" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ProposalCard from "./ProposalCard.vue";

import proposalMixin from "~/mixins/proposal";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "ProposalGrosMarketGrid",
  props: {
    showProposalContent: { type: Boolean, required: false, default: false }
  },
  mixins: [proposalMixin, clickHandler],
  components: { ProposalCard },
  data() {
    return {};
  }
};
</script>
<style scoped lang="scss">
h2 {
  max-width: 500px;
  font-size: 26px;
  line-height: 28px;
  color: $secondary;
  font-weight: 600;
  text-align: center;
  margin-bottom: 50px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 22px;
    margin-bottom: 30px;
  }
}
</style>
