<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="3"
        order="last"
        order-md="first"
        class="pa-0 pa-md-3"
      >
        <FaqCard class="mb-0 mb-md-5" :small="true" />
        <SupportCard class="mb-0 mb-md-5" />
        <BeCustomerCard
          class="mb-0 mb-md-5"
          v-if="!isAuthenticated && showBeCustomerCard"
        />
      </v-col>
      <v-col cols="12" md="9">
        <!-- <div v-html="content" @click="clicked"></div> -->
        <v-runtime-template :template="template" @click="clicked" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import clickHandler from "~/mixins/clickHandler";
import SupportCard from "@/components/cards/SupportCard";
import FaqCard from "@/components/cards/FaqCard";
import BeCustomerCard from "@/components/cards/BeCustomerCard";
import VRuntimeTemplate from "v-runtime-template";
import javascriptLoader from "@/components/wordpress/javascriptLoader.vue";

import { mapGetters } from "vuex";
import get from "lodash/get";
export default {
  name: "PageTwoColumn",
  mixins: [clickHandler],
  data() {
    return {
      template: null
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    SupportCard,
    FaqCard,
    BeCustomerCard,
    VRuntimeTemplate,
    javascriptLoader
  },
  props: { page: { type: Object, required: true } },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    content() {
      return get(this.page, "content.rendered");
    },
    showBeCustomerCard() {
      return this.page.slug != "codice-etico";
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.page && this.page.content) {
        this.template = `<div>${this.page.content.rendered}</div>`;
      }
    });
  }
};
</script>
