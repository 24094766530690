<template>
  <v-card
    color="primary lighten-1"
    elevation="0"
    class="be-customer-card pa-2 d-none d-md-flex flex-column align-center white--text"
    height="220px"
    style="background-image: url('/cards/be-customer-card-vertical.jpg')"
  >
    <h3 class="title text-center mt-8">
      <strong>{{ $t("leftColumnCards.beCustomer.title") }}</strong>
    </h3>
    <v-btn
      dense
      depressed
      text
      :to="'/registration-user'"
      class="contact-button white--text primary"
    >
      {{ $t("leftColumnCards.beCustomer.button") }}
    </v-btn>
  </v-card>
</template>
<style scoped lang="scss">
.v-card {
  background-size: cover;
  background-position: center;
}
.help-img {
  width: 60px;
  height: 60px;
}
h3.title {
  color: var(--v-secondary-base);
  line-height: 24px;
}
.v-btn {
  bottom: 30px;
  position: absolute;
}
</style>
<script>
export default {
  name: "BeCustomerCard"
};
</script>
