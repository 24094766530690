<template>
  <div class="wp-page" @click="clicked">
    <v-runtime-template :template="template" />
  </div>
</template>
<script>
import NinjaForm from "~/components/ninjaForms/NinjaForm.vue";
import javascriptLoader from "@/components/wordpress/javascriptLoader.vue";

import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VDialog
} from "vuetify/lib";

import VRuntimeTemplate from "v-runtime-template";

import get from "lodash/get";

import clickHandler from "~/mixins/clickHandler";
// import page from "~/mixins/page";

export default {
  name: "PageFullWidth",
  mixins: [clickHandler],
  data() {
    return {
      template: null
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VDialog,
    NinjaForm,
    javascriptLoader
  },
  props: { page: { type: Object, required: true } },
  computed: {
    deliveryServiceId() {
      return get(this.page.deliveryserviceid);
    },
    title() {
      return this.page.hide_title == "1"
        ? null
        : get(this.page, "title.rendered");
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.page && this.page.content) {
        this.template = `<div>${this.page.content.rendered}</div>`;
      }
    });
  },
  updated() {
    this.$nextTick(() => {
      // Set dynamic overlay background image
      if (
        this.page?.yoast_head_json?.og_image &&
        this.page.yoast_head_json.og_image.length > 0 &&
        this.page.yoast_head_json.og_image[0].url
      ) {
        const bgUrl = this.page?.yoast_head_json?.og_image[0].url;
        let boxOverlay = document.getElementsByClassName(
          "box-automatic-overlay"
        );
        if (boxOverlay && boxOverlay.length > 0)
          boxOverlay[0].style.backgroundImage = "url('" + bgUrl + "')";
      }
    });
  }
};
</script>
