<template>
  <v-container>
    <v-runtime-template :template="template" @click="clicked" />
  </v-container>
</template>
<script>
import NinjaForm from "~/components/ninjaForms/NinjaForm.vue";
import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle
} from "vuetify/lib";

import VRuntimeTemplate from "v-runtime-template";

import get from "lodash/get";

import clickHandler from "~/mixins/clickHandler";
// import page from "~/mixins/page";
import ProductListSlider from "@/components/wordpress/WpProductListSlider.vue";
import javascriptLoader from "@/components/wordpress/javascriptLoader.vue";
import EventsList from "@/components/wordpress/EventListSlider.vue";

export default {
  name: "Static",
  mixins: [clickHandler],
  data() {
    return {
      template: null
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    NinjaForm,
    ProductListSlider,
    javascriptLoader,
    EventsList
  },
  props: { page: { type: Object, required: true } },
  computed: {
    deliveryServiceId() {
      return get(this.page.deliveryserviceid);
    },
    title() {
      return this.page.hide_title == "1"
        ? null
        : get(this.page, "title.rendered");
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.page && this.page.content) {
        this.template = `<div>${this.page.content.rendered}</div>`;
      }
    });
  }
};
</script>
